@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

#root {
  --blue: #1e90ff;
  --white: #ffffff;
  --yellow: #d89d1d;
  --error-red-color: #ff0000;
  --success: #008000;
  --off-white: #f2f2f2;
  --black: #0f172a;
  --secondary: #4c6072;
  --grey-white: #94a3b8;
  --primary-color: #00a88e;
  --grey-color: #94a3b8;
  --blue-color: #3862ee;
  --light-blue: #5398ff;
  --purpal-color: #8767ff;
  --light-purpal: #a65ce8;
}

$primary-color: #00a88e;
$white_color: #fff;
$mostly_black: #0f172a;
$light_grey: #94a3b8;
$primary-color: #00a88e;
$admin_background: #f1f5f9;

* {
  box-sizing: border-box;
}

body {
  font-family: "Inter" !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--border-color) !important;
}

span {
  font-family: "Inter" !important;
}

html {
  scroll-behavior: smooth;
  font-family: "Inter" !important;
}

/* Common Css Start */
* body,
body,
button,
input,
select,
textarea,
h6,
h1,
h2,
h3,
h4,
h5,
h6,
a {
  font-family: "Inter" !important;
  color: var(--secondary-color);
}

* {
  margin: 0;

  &:focus {
    outline: none !important;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    font-weight: 800 !important;
  }

  h1 {
    font-size: 32px;
    line-height: normal;
  }

  h2 {
    font-size: 24px;
    line-height: normal;
  }

  h3 {
    font-size: 18px;
    line-height: normal;
  }

  p {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none !important;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .w-full {
    width: 100%;
  }

  .h-full {
    height: 100vh;
  }

  .text-underline {
    text-decoration: underline;
  }

  .BorderRadius {
    border-radius: var(--border-radius-pixel);
  }

  .error {
    position: absolute;
    font-size: 12px;
    color: var(--error-red-color) !important;
    margin-left: 2px;
    margin-top: 1px;
  }

  .suggestion {
    font-size: 12px !important;
    margin-left: 2px !important;
    margin-top: 1px !important;
  }

  .suggestion-error,
  .profile-error {
    font-size: 12px !important;
    margin-left: 2px !important;
    margin-top: 1px !important;
    color: var(--error-red-color) !important;
  }

  .no-internet-connection {
    color: var(--secondary-color);

    img {
      height: 80px;
    }
  }
}

.MuiDialog-root {
  z-index: 9999 !important;
}

.show-hide {
  cursor: pointer;
}

body {
  .MuiSelect-select {
    &:focus {
      border-radius: 0;
      background-color: transparent;
    }
  }
  .MuiIconButton-root {
    &:hover {
      background-color: transparent !important;
    }
  }
}

.disabled {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}

.green {
  color: var(--success) !important;
  font-weight: 600 !important;
}

.blue {
  color: #9e9e9e !important;
  font-weight: 600 !important;
}

.red {
  color: var(--error-red-color) !important;
  font-weight: 600 !important;
}

.show-pointer {
  cursor: pointer;
}

.yellow {
  color: var(--yellow) !important;
  font-weight: 600 !important;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.flex-row {
  flex-direction: row !important;
}

.h-20 {
  height: 20px;
}

.back-cursor {
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize;
}

.blue-color {
  color: var(--blue-color) !important;
}

.purpal-color {
  color: var(--purpal-color) !important;
}

.light-blue {
  color: var(--light-blue) !important;
}

.light-purpal {
  color: var(--light-purpal) !important;
}

.height-100 {
  height: 100%;
}

/* Common Css Ends */

.nav-bar-wrapper {
  width: 100%;

  .nav-right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user-name-email {
      .setting-option {
        width: 100%;
        display: flex;
        align-items: center;

        img {
          width: 35px;
        }

        .bottom-email-sec {
          width: calc(100% - 30px);
          position: relative;
          padding: 0 0 0 14px;

          &.open-dropdown {
            .user-profile-text {
              img {
                -ms-transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                transform: rotate(180deg);
              }
            }
          }
        }

        .option-dropdown {
          text-align: right;
          cursor: pointer;
          width: auto;
          display: inline-block;
          float: right;
          margin-top: -2px;

          img {
            cursor: pointer;
          }

          &:hover {
            color: var(--primary-color);
            text-decoration: underline;
          }
        }
      }
    }
  }

  &.closed {
    margin-left: 68px;
  }
}

.MuiAutocomplete-popper {
  z-index: 9999 !important;

  > div {
    box-shadow: var(--boxShadow-color) !important;
  }
}

.MuiToolbar-regular {
  min-height: 100%;
  padding: 30px;

  &.MuiTablePagination-toolbar {
    min-height: 100%;
    padding: 15px 30px;
    border-top: 1px solid var(--light-grey-color);
  }
}

.cus-head-logo {
  box-shadow: none !important;
  padding: 0;
  z-index: 999999;
  justify-content: center;
  margin: 20px 0 0px;

  .logo-icon {
    height: 42px;
  }

  .logo-icon-desktop {
    height: 90px;
    margin-top: 30px;
  }
}

.MuiOutlinedInput-root.Mui-focused {
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--secondary-color);
    border-width: 2px;
  }
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0;
}

.nav-right {
  &.dashbord {
    padding-top: 14px;
  }

  span {
    &.badgec svg {
      height: 18px;
      width: 18px;
      margin-right: 4px;
    }
  }
}

span {
  &.badgec {
    svg {
      height: 18px;
      width: 18px;
      margin-right: 4px;
    }
  }
}

.MuiFormLabel-root {
  &.Mui-focused {
    color: var(--secondary-color);
    font-weight: 900;
  }
}

.table-loader {
  height: 350px;
  justify-content: center;
  display: flex;
  align-items: center;

  .MuiCircularProgress-root.MuiCircularProgress-indeterminate {
    color: color(secondary) !important;
  }

  /*** Login ***/
  /* End LogIn Css */
  .list-style {
    padding: 0;
    margin: 0;

    li {
      width: auto;
      list-style: none;
      margin: 0 0px 0 20px;

      &:first-child {
        margin: 0;
      }

      .select__control {
        min-width: 240px;
      }
    }

    .MuiTableCell-head {
      font-weight: 800;
    }

    img.toggle-btn {
      height: 18px;
    }

    .loader-circle-color {
      color: var(--color-white);
    }

    .button {
      &:hover {
        background-color: var(--black-light-color);
      }
    }
    &.selected {
      background-color: var(--primary-color);
      color: var(--color-white);
    }
  }

  .custom-modal {
    border-radius: 16px;
  }

  .heading-title-box {
    padding: 20px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  #long-menu {
    > div {
      border-radius: 8px;

      ul {
        padding: 0;
      }
    }

    ul {
      li {
        border-bottom: 1px solid var(--light-grey-color);

        img {
          width: 12px;
          height: 12px;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .back-sec {
    h3 {
      font-size: 20px;
      white-space: nowrap;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
    }

    h2 {
      margin: 0;
      display: flex;
      align-items: center;
      line-height: normal;
      font-size: 24px;

      svg {
        font-size: 24px;
        height: auto;
        position: relative;
        top: -1px;
      }
    }
  }
}

main {
  .MuiInput-underline:after {
    border-bottom: 2px solid var(--secondary-color);
  }

  .MuiFilledInput-underline:after {
    border-bottom: 0;
  }

  .MuiInputLabel-filled {
    transform: translate(12px, 14px) scale(1);
    -moz-transform: translate(12px, 14px) scale(1);
    -webkit-transform: translate(12px, 14px) scale(1);
    font-size: 16px;

    &.MuiInputLabel-shrink {
      transform: translate(12px, 0) scale(0.75);
      -moz-transform: translate(12px, 0) scale(0.75);
      -webkit-transform: translate(12px, 0) scale(0.75);
    }
  }

  .MuiFilledInput-input {
    padding: 16px 12px 10px;
  }

  .card-custom {
    height: 100%;
    border-radius: 12px;
    box-shadow: var(--boxShadow-color);
  }

  .MuiFormLabel-root {
    color: var(--border-color);
  }

  .title-heading {
    font-size: 20px;
    margin: 0;
  }

  .table-design {
    background: var(--color-white);
    box-shadow: var(--boxShadow-color) !important;
    border-radius: 5px;
    padding: 0px 0 0;
    margin-top: 20px;
    overflow: auto;

    tr.MuiTableRow-root {
      &.MuiTableRow-hover:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }

  .more-btn {
    color: var(--black-color);
    opacity: 1;
  }

  .table-program {
    tbody {
      td {
        color: var(--secondary-color);
        &.green {
          color: var(--success) !important;
        }

        svg {
          color: var(--secondary-color);
          height: 20px !important;
        }
      }

      tr {
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }

    th {
      line-height: normal;
      color: var(--secondary-color);
      background-color: var(--header-border) !important;
      font-weight: 700;

      svg {
        color: var(--secondary-color);
        height: 20px !important;
      }

      .icon {
        color: var(--secondary-color);
        height: 15px !important;
      }

      &.more {
        position: sticky;
        width: auto;
        right: 0;
        background-color: var(--offwhite);
      }
    }

    td {
      word-break: break-word;
      white-space: nowrap;

      button {
        padding: 0 !important;
        line-height: 0 !important;

        &:hover {
          background-color: transparent;
        }
      }

      &.more {
        position: sticky;
        width: auto;
        right: 0;
        background: var(--color-white);
      }

      .show-more-span {
        color: var(--error-red-color) !important;

        font-family: "Inter";
      }
    }

    th,
    td {
      padding: 12px 10px;
      min-width: 140px;
      word-break: normal;
      white-space: nowrap;
      border-bottom: 1px solid var(--header-border);

      &:first-child,
      &:first-child {
        padding-left: 25px;
      }

      .more-button {
        background: var(--primary-color);
        padding: 4px 6px 2px 10px;
        border-radius: 30px;
        color: var(--color-white);
        font-size: 12px;
        text-align: center;
        display: inline-block;

        svg {
          color: var(--color-white) !important;
          width: 20px;
        }
      }

      .MuiChip-label {
        text-transform: capitalize;
      }
    }

    [type="button"]:not(:disabled) {
      cursor: pointer;
    }

    .partner-badge {
      border: 1px solid var(--light-grey-color);
      padding: 3px 10px;
      border-radius: 30px;
      font-size: 12px;
      text-align: center;
      display: inline-block;
      text-transform: capitalize;
      margin-right: 4px;
    }
  }

  .table-design {
    display: grid;
    overflow: auto;

    > div {
      overflow: auto;
    }
  }
}

.user-profile-text {
  display: flex;
  align-items: center;

  img {
    cursor: pointer;
  }

  .option-dropdown {
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    margin-right: 15px;

    h5 {
      line-height: 0.8;
      font-size: 16px;
      font-weight: 700 !important;
      color: var(--secondary-color);
    }

    span {
      font-size: 12px;
    }
  }

  img {
    width: 22px;
  }
}

.logout-text {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #1e293b;
  cursor: pointer;

  svg {
    cursor: pointer;
    color: var(--white);
  }

  .option-dropdown {
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    line-height: 0.8;
    font-size: 14px;
    color: var(--white);

    span {
      font-size: 12px;
    }
  }

  img {
    width: 22px;
  }
}

/** Sidebar **/
.custom-menu {
  background: var(--black);

  > div {
    justify-content: space-between;
    background: var(--black);
    overflow: hidden;
    border-right: 1px solid var(--header-border);
  }

  .sidebar-menus {
    height: calc(100% - 70px);
    overflow: hidden;
    overflow-y: auto;
    border-right: 1px solid var(--black);
    padding: 20px 12px 0;
    margin-top: 40px;

    &::-webkit-scrollbar-track {
      background-color: var(--color-white);
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: var(--color-white);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-color);
      border-radius: 10px;
    }

    h6 {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 600 !important;
    }

    .active {
      h6 {
        color: var(--primary-color);
      }
    }

    .sidepanel-item {
      margin: 0 0 10px;
      padding: 7px 12px;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;

      &.active {
        opacity: 1;
        background-color: var(--primary-color);

        .less-icon {
          color: var(--white);
        }

        img {
          filter: brightness(0) invert(1);
        }
      }

      .sidebar-menu-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 18px;
        color: var(--grey-white);

        svg,
        img {
          width: 18px;
          height: 18px;
        }

        &.active {
          color: var(--white);
        }
      }

      .sidebar-menu-text {
        margin: 0 0 0 8px;
        color: var(--grey-white);
        font-size: 14px;

        &.active {
          color: var(--white);

          span {
            color: var(--white);
          }
        }

        span {
          font-size: 14px;
        }
      }

      &:hover {
        opacity: 1;
      }

      svg.MuiSvgIcon-root {
        fill: var(--white);
      }
    }

    > .sidepanel-item:first-child {
      .sidebar-menu-icon {
        img {
          width: 18px;
        }
      }
    }

    .MuiCollapse-entered {
      // margin-right: 8px;
      overflow: hidden;

      .active {
        background: transparent !important;
        color: var(--primary-color) !important;

        span {
          color: var(--primary-color) !important;
        }
      }
    }
  }

  &.makeStyles-drawerClose-9 {
    .sidebar-menus {
      .sidepanel-item {
        width: 100% !important;
        margin-left: 0 !important;
      }
    }
  }

  &.heat-pump-sidebar {
    > div {
      background-color: #3a4e55 !important;
    }
    .heading-banner {
      background-color: #02a88e;
      width: 100%;
      margin-top: 40px !important;
      color: #fff;
      padding: 0.25rem;
      text-align: center;
      font-size: 20px;
      line-height: 1.5;
      font-weight: 500;
      transition: all 0.3s linear;
    }
    &.makeStyles-drawerClose-9 {
      .heading-banner {
        font-size: 12px;
      }
    }
    .sidebar-menus {
      margin-top: 10px;
    }
    .user-name-email {
      .logout-text {
        background-color: rgb(0 0 0 / 25%);
      }
    }
  }
}

.top-header {
  box-shadow: none !important;
  border-bottom: 1px solid var(--header-border);

  .header-inner {
    height: 69px;
    padding: 10px 24px;

    .nav-bar-wrapper {
      width: 100%;

      .device-list {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0;
        gap: 16px;

        li {
          color: var(--text-main);
          padding: 8px 20px;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-border-radius: 4px;
          font-weight: 600;
          cursor: pointer;

          &.selected-product {
            background-color: var(--black-color);
            color: var(--black-white);
          }
        }

        span {
          fill: var(--black-color);
        }
      }
    }
  }
}

//For Public Site
.text-left {
  text-align: left !important;
}

* {
  body {
    .DateRangePicker_picker {
      z-index: 99;
    }
  }
}

main {
  .table-program {
    .cursor_default {
      tbody td {
        cursor: auto;

        .cursor_pointer {
          cursor: pointer;
        }
      }
    }
  }
}

.cursor_pointer {
  cursor: pointer;
}

.font-weight600 {
  font-weight: 600 !important;
}

.text_current_bal {
  color: var(--yellow) !important;
}

.hover_underLine:hover {
  text-decoration: underline;
}

.input-group {
  position: relative;

  .help-block {
    left: 0;
    position: absolute;
    bottom: -18px;
    color: var(--error-red-color);
  }
}

.logout_hover:hover {
  color: var(--yellow) !important;
}

body {
  background-color: var(--bg-light) !important;

  .MuiAutocomplete-popper {
    ul {
      padding: 0 !important;
    }
  }
}

.dashboard {
  width: 100%;
}

div#chart,
.apexcharts-canvas {
  width: 100% !important;
}

.apexcharts-canvas {
  svg {
    width: 100%;
  }
}

.boxshadow-custom {
  background: var(--color-white);
  box-shadow: var(--boxShadow-custom) !important;
  -moz-box-shadow: var(--boxShadow-custom) !important;
  -webkit-box-shadow: var(--boxShadow-custom) !important;
  border-radius: 4px;
  color: var(--black-color);

  .input-group {
    button {
      color: var(--border-color);
    }
  }
}

.menu-icon {
  svg {
    color: var(--light-grey-color);
  }
}

.custom-table-pagination {
  border-top: 1px solid var(--border-main);
  padding: 10px 20px;

  .pagination-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .showing-items {
      display: flex;
      align-items: center;
      padding: 9px 20px;
      border-radius: 30px;
      color: var(--secondary-color);

      P {
        font-family: "Inter";

        span {
          color: var(--primary-color);
          font-weight: 600;
        }
      }

      svg {
        color: var(--primary-color);
        font-size: 18px;
        margin-left: 15px;
        cursor: pointer;
      }

      .table-pagination {
        margin-top: 15px;

        button {
          height: 20px;
          margin: 0 0;
          padding: 0 4px;
          min-width: 20px;
          color: var(--black-color);
        }
      }

      @keyframes refresh-svg {
        from {
          -webkit-transform: rotate(0deg);
        }

        to {
          -webkit-transform: rotate(360deg);
        }
      }

      .refresh-svg {
        animation: refresh-svg;
        -webkit-animation: refresh-svg 2s linear infinite;
      }
    }

    .MuiPagination-ul {
      .MuiPaginationItem-page {
        border-radius: 6px;
        color: var(--secondary-color);

        &.Mui-selected {
          border-radius: 6px;
          background-color: var(--primary-color);
          color: var(--white);
        }

        &:hover {
          background-color: var(--black-light-color);
        }
      }

      li {
        &:first-child button,
        &:nth-child(2) button,
        &:last-child button,
        &:nth-child(10) button {
          margin: 0;
          padding: 0 !important;
        }

        &.MuiPaginationItem-root {
          border-radius: 0;
        }

        .MuiPaginationItem-icon {
          color: var(--primary-color);
        }

        & > div {
          color: var(--secondary-color);
        }
      }
    }
  }

  .heat_pump_custom_table_pagination {
    padding: 10px 18px !important;
  }
  .heatpump-pagination-div {
    justify-content: end;
    .MuiTablePagination-caption:first-child {
      color: #94a3b8;
      font-family: "Inter" !important;
    }
    .MuiTablePagination-actions {
      Button {
        padding: 0px !important;
      }
    }
  }
}

.brands-title {
  text-transform: capitalize;
}

.edit-pop-up {
  li {
    cursor: default;
  }
}

.common-add-component {
  .header {
    padding: 0 0 20px 0;
    position: relative;

    .img-add-inner {
      height: 18px;
      margin-right: 10px;
    }
  }

  .content {
    padding: 20px;
  }

  .footer {
    padding: 20px;

    .buttons-div {
      display: flex;
      float: right;

      button {
        min-width: 160px;
        margin: 0 10px;
        padding: 10px 16px;
      }
    }
  }
}

.textarea-input-field {
  width: 100%;
  border-radius: 4px;
  padding: 10px;

  font-family: "Inter";
  font-size: 1rem;
  border-color: var(--black-light-color);
  overflow: auto;
  resize: none;

  &::placeholder {
    color: var(--gray-color);
  }

  &:hover {
    border-color: var(--black-light-color);
  }

  &:focus {
    border-color: var(--black-color);
    border-width: 2px;
  }

  &.error-active {
    border-color: var(--error-red-color);
  }
}

.color-theme {
  color: var(--primary-color);

  &:hover {
    color: var(--secondary-color);
  }
}

/* Page Heading */
.location-container {
  padding: 25px;

  .location-head {
    .heading {
      h2 {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 600;

        svg {
          color: var(--primary-color);
          font-size: 32px;
          margin-right: 10px;
        }

        img {
          width: 24px;
          margin-right: 10px;
          height: 24px;
        }
      }
    }

    &.btn-edit-tooltip {
      .MuiTooltip-tooltip {
        font-size: 22px !important;
      }
    }

    .content-container {
      .show-more-span {
        color: var(--primary-color);
      }
    }

    .search-add {
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;

      button {
        &.filter-btn-style {
          position: absolute;
          right: 0;
          background: $white_color 0% 0% no-repeat padding-box;
          border-radius: 5px 0px 0px 5px;
          opacity: 1;
          border: 0;
          width: 60px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0 !important;
          cursor: pointer;
        }
      }

      .custom-search-field {
        input[type="text"] {
          padding: 12px 12px;
          margin: 0 16px;
          background: $white_color;
          box-shadow: none;
          border: 1px solid #f2f2f2;
          color: #000;
          border-radius: 4px;
        }
      }
    }
  }

  .add-loader {
    svg {
      color: var(--color-white) !important;
    }
  }
}

/* Back Button */
.back-btn {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: var(--primary-color);
  font-weight: 600;
  cursor: pointer;

  .back-icon {
    width: 24px;
    background: var(--color-white);
    text-align: center;
    color: var(--primary-color);
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--gray-color);
    margin-right: 10px;
  }

  svg {
    width: 14px;
    color: var(--secondary-color);
    margin-left: 4px;
  }
}

.heading {
  display: flex;
}

.transaction-page {
  justify-content: flex-end;
}

.update-circular-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.astrick-sign {
  color: var(--error-red-color);
}

.download-csv {
  svg {
    border-radius: 50%;
    border: 1px solid;
    width: 44px;
    height: 44px;
    padding: 6px;
  }

  &.no-device {
    svg {
      color: var(--secondary-color);
    }
  }

  &.devices {
    svg {
      color: var(--primary-color);
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.download-csv-imgicon {
  border-radius: 50%;
  border: 1px solid;
  width: 44px;
  height: 44px;
  padding: 7px;
  color: var(--primary-color);

  &.devices {
    svg {
      color: var(--primary-color);
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.Csv-downloading {
  margin-top: 12px;
  padding-left: 10px;
}

.select-custom {
  position: relative;

  select {
    border-color: var(--light-grey-color);
    display: flex;
    align-items: flex-start;
    background: var(--light-grey-color);
    appearance: none;
    background: url("../../assets/download.png") no-repeat 82% / 11px #f1f1f1;
    border-radius: 30px;
    padding: 10px 16px;
    min-width: 80px;
    border: none;
    color: var(--black);
  }
}

.formgroup-help-icon {
  font-size: 18px !important;
  margin-left: 5px !important;
  margin-bottom: 2px !important;
}

.input-text {
  margin: 0 0 24px !important;
  font-size: 14px !important;
  word-break: break-all;
  padding-right: 22px;
}

.show-table {
  th {
    font-size: 16px;
  }
}

.background-theme-color {
  padding: 0;
  margin: 40px 0 16px !important;
  border-radius: 4px 4px 0 0;
  position: relative;
  font-size: 24px;
  line-height: normal;
  color: var(--secondary-color);

  .error.ml {
    bottom: -18px;
    left: 0;
    font-weight: 400;
  }
}

.no-background-theme-color {
  background-color: var(--bg-light);
  color: var(--black-color);
  padding: 12px;
  margin: 24px 0 !important;
}

td.table-no-data {
  text-align: center;
  padding: 20px !important;
  font-size: 18px;
  font-weight: 600;
}

.update-circular-loader {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resend-signup-verification-dialog {
  padding: 0px 15px !important;
}

.cus-dialog-login {
  .dialog-btns button.btn-custom-black-2 {
    margin: 0 !important;
    color: #000;
    background: #ededed !important;
  }
}

.input-custom-checkbox {
  label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementTop {
    margin: 0 0 10px;
    align-items: end;
  }

  .MuiSwitch-root {
    left: -12px !important;
  }
}

.input-custom-checkbox > div > div > div > p {
  color: var(--secondary-color);
}

.ota-select {
  .MuiSelect-select {
    width: 50px;
  }
}

.form-control {
  width: 100%;
  display: flex;
  border: 0px;
  box-shadow: none !important;

  input {
    height: 48px;
    width: 98px;
    padding: 10px;
    font-size: 16px;
    margin-left: 20px;
  }

  &::after,
  &::before {
    border: 0px;
    width: 0;
  }
}

.add-btn1 {
  .button-btn-add {
    height: 48px;
    background: var(--primary-color);
    color: var(--color-white);
    margin: 20px 20px 20px 0;

    &:hover {
      background-color: var(--black-color);
    }
  }
}

.drop-files {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0px;

  .cus-img-wrappe {
    margin-left: 10px;
    font-size: 10px;
  }
}

.modal-add-pdf {
  justify-content: center !important;
}

.custom-list-style {
  display: flex;
  align-items: center;
  list-style: none;
  background: var(--color-white);
  justify-content: center;
  padding: 9px 16px;

  li {
    margin-right: 20px;
    color: var(--secondary-color);

    &.target-version {
      width: 200px;

      label {
        background: var(--color-white);
      }

      .ota-select {
        .MuiSelect-select {
          width: 120px;
        }
      }
    }

    .button-btn {
      min-width: 130px;
    }
  }

  &.custom-line {
    font-size: 16px;
    padding: 20px;
    background: var(--color-white);
    color: var(--secondary-color);
  }
}

.add-file-dropzone {
  cursor: pointer;
  border: 2px dotted var(--gray-color);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 2px;
}

/* Timeline */
.location-container {
  width: 100%;
}

.timeline-header {
  margin-bottom: 40px !important;
}

.timeline {
  border-left: 8px solid var(--primary-color);
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: 20px 40px 40px;
  list-style: none;
  text-align: left;
  width: 100%;

  .event {
    padding-bottom: 25px;
    margin-bottom: 50px;
    position: relative;

    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }

    &:before,
    &:after {
      position: absolute;
      display: block;
      top: 0;
    }

    &:before {
      left: -217.5px;
      color: rgba(255, 255, 255, 0.4);
      content: attr(data-date);
      text-align: right;
      font-weight: 100;
      font-size: 0.9em;
      min-width: 120px;
    }

    &:after {
      box-shadow: 0 0 0 6px var(--primary-color);
      left: -52.85px;
      background: var(--color-white);
      border-radius: 50%;
      height: 18px;
      width: 18px;
      content: "";
      top: 5px;
    }
  }
}

div#support-document {
  border: 2px dotted var(--bg-light);
  padding: 9px;
  width: 100%;
}

.custom-dropzone-image-wrapper {
  img {
    width: 48px !important;
  }
}

#firmware {
  width: 100%;
}

.dropzone-text {
  text-align: center;
  font-size: 16px;
}

.image-custom-dropzone-div {
  svg {
    width: 18px;
    height: auto;
    cursor: pointer;
  }
}

.text-more {
  padding-left: 5px;

  &:hover {
    cursor: pointer;
    color: var(--primary-color);
  }
}

.OTA-all-device-selected {
  cursor: pointer;
  margin-top: 1px;
}

.table-program {
  .MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: transparent;
  }
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: rgb(132 147 167);

  &:hover {
    background-color: transparent !important;
  }
}

.drop-down-custom {
  max-width: 260px;
  min-width: 260px;

  .MuiAutocomplete-tag {
    height: 22px;
    margin: 1px 3px;
    max-width: calc(100% - 6px);
    font-size: 14px;

    .MuiChip-deleteIcon {
      width: 18px;
      height: 16px;
      margin: 0 5px 0 -6px;
    }

    .MuiChip-label {
      padding-bottom: 1px;
    }
  }
}

.cus-img-wrappe {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .image-custom-dropzone-div {
    text-align: center;
  }
}

body {
  .table-design {
    .Mui-selected {
      .table-custom-select {
        .MuiInputLabel-outlined.MuiInputLabel-shrink {
          transform: translate(14px, -6px) scale(0.75) !important;
          background: var(--bg-light);
        }

        .MuiInputLabel-outlined {
          background: var(--bg-light);
        }
      }
    }

    .MuiTableRow-hover {
      &:hover {
        .table-custom-select {
          .MuiInputLabel-outlined.MuiInputLabel-shrink {
            background: var(--bg-light);
          }

          .MuiInputLabel-outlined {
            background: var(--bg-light);
          }
        }
      }
    }

    .table-custom-select {
      label {
        background: var(--color-white);
      }

      .MuiInputLabel-outlined {
        z-index: 1;
        transform: translate(8px, 12px) scale(1) !important;
        pointer-events: none;
        font-size: 12px;
      }

      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75) !important;
      }
    }

    .MuiOutlinedInput-input {
      padding: 8.5px 14px;
      font-size: 14px;
    }
  }
}

.no-OTA {
  cursor: auto;
  opacity: 0.6;
}

.show-ota-history {
  &:hover {
    color: var(--primary-color);
    cursor: pointer;
  }
}

.sidepanel-item {
  .less-icon {
    color: var(--secondary-color);
  }
}

.OTA-serial {
  color: var(--text-main);

  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
    color: var(--primary-color);
  }
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  .MuiAutocomplete-input {
    &:first-child {
      padding-top: 6px;
      padding-bottom: 5px;
    }
  }
}

.input-section-edit {
  .error {
    position: relative;
    margin-bottom: 10px !important;
    text-align: left;
  }
}

.installtion {
  h4 {
    color: var(--primary-color);
    font-size: 18px;
    margin: 30px 0 10px !important;

    &:first-child {
      margin-top: 0 !important;
    }
  }

  strong {
    color: var(--primary-color);
  }

  li {
    margin: 0 0 8px;
    font-size: 15px;

    &::marker {
      color: var(--primary-color);
      font-size: 20px;
    }
  }

  p {
    margin-bottom: 12px !important;
    font-size: 15px;

    .text-green {
      color: var(--primary-color);
      font-weight: 900;
      font-size: 15px;
      text-transform: capitalize;
    }
  }
}

.add-agent-container {
  .po-rel {
    position: relative;
  }

  .error {
    left: 12px;
  }
}

.MuiInputLabel-outlined {
  transform: translate(14px, 16px) scale(1) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75) !important;
}

.copied-icon {
  padding-left: 12px;
  padding-bottom: 0px;
  height: 21px;
  padding-top: 4px;
}

.copied {
  color: var(--success) !important;
  font-weight: 600;

  .copied-text {
    margin-top: 3px;
  }

  .MuiSvgIcon-root {
    font-size: 18px;
    margin-top: 4px;
    margin-right: 2px;
  }
}

.datepicker {
  .DateRangePicker {
    .CalendarDay__selected {
      background-color: var(--primary-color) !important;

      &:active {
        background-color: var(--primary-color);
      }

      &:hover {
        background-color: var(--primary-color);
      }
    }

    .CalendarDay__selected_span {
      background-color: var(--primary-color);
    }
  }

  .DateRangePickerInput_clearDates__hide {
    visibility: initial !important;
  }

  .DateRangePickerInput_clearDates_default {
    &:focus {
      background: transparent;
      border-radius: 50%;
    }

    &:hover {
      background: transparent;
      border-radius: 50%;
    }
  }

  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background: transparent;
    border: 1px double var(--black);
    color: var(--gray-color);
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    border: 1px double var(--color-white);
    color: var(--white);
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span:active {
    background: var(--primary-color);
    border: 1px double var(--color-white);
    color: var(--color-white);
  }

  .CalendarDay__selected_span {
    background-color: transparent;
    border: 1px double var(--color-white);
  }

  .DateRangePickerInput_arrow_svg {
    fill: var(--black-color);
  }

  .DateRangePickerInput {
    border-radius: 4px;
    border: 1px solid var(--black-color);
    display: flex;
    align-items: center;
    background-color: transparent;

    ul {
      li {
        margin: 0;
      }
    }

    .DateInput {
      border-radius: 4px;
      width: 110px;
      background-color: transparent;

      .DateInput_input {
        font-size: 14px;
        color: var(--secondary-color);
        border-radius: 4px;
        font-family: "Inter";
        background-color: transparent;

        &::placeholder {
          color: var(--black-color);
        }
      }

      .DateInput_input__focused {
        border-bottom: 2px solid transparent;
      }
    }

    .DateRangePickerInput_arrow {
      color: var(--black-color);
      width: 42px;
      margin: 0 auto;
      text-align: center;
    }

    .DateRangePickerInput_calendarIcon {
      padding: 0;
      margin: 0 10px 0 10px;

      svg {
        fill: var(--primary-color);
        width: 16px;
        height: 22px;
      }
    }
  }

  .DateInput_input {
    font-weight: 500;
    min-height: 48px;
    padding: 0 10px;
    line-height: 1.5;
  }

  .CalendarDay__default {
    &:hover {
      background: var(--primary-color);
    }
  }
}

.MuiAppBar-colorPrimary {
  color: var(--primary-color);
  background-color: var(--color-white) !important;
}

/** Show Data Common CSS **/
.show-data {
  label {
    font-size: 14px;
    color: var(--secondary-color);
  }

  p {
    &.MuiTypography-colorTextSecondary {
      color: rgb(0 168 142);
    }
  }
}

.top-bottom {
  .MuiInputBase-root {
    color: var(--secondary-color);
  }
}

body {
  .bg-white {
    background-color: var(--color-white);
  }
}

.device-id {
  min-width: 300px !important;
}

body {
  .MuiOutlinedInput-input {
    padding: 14px;
    color: var(--black-color);
  }

  .MuiOutlinedInput-root {
    svg {
      color: var(--secondary-color);
    }
  }
}

.tariff-custom {
  padding: 16px;
  border-radius: 4px;
  color: var(--black-color);
  border: 2px solid var(--black-color);

  p.input-text {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 !important;
  }
}

.loader {
  width: 100px;
}

.upload-csv-file {
  display: flex;

  img {
    height: 48px;
    cursor: pointer;
  }

  aside + div > span {
    margin-top: 20px !important;
    background-color: var(--primary-color) !important;
  }
}

.no-data-found {
  width: 100%;
  text-align: center;
  justify-content: center;
  clear: both;
  padding: 0 0 20px;
  font-weight: 700;
  font-size: 18px;
  color: var(--text-main);
}

.btn-edit-tooltip {
  div {
    .MuiTooltip-tooltip {
      font-size: 22px !important;
      background-color: var(--error-red-color) !important;
    }
  }
}

/* Custom Password Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 60px;
  width: 100%;

  .tooltiptext {
    background-image: url("../image/passwordCard.png");
    background-size: 100% 100%;
    margin-top: -15px;
    width: 100%;
    border-radius: 6px;
    position: absolute;
    z-index: 999;
    top: 150%;
    left: 0;
    margin-left: -75px;
    visibility: visible;
    display: block;
    padding: 25px 15px 10px 15px;

    &::after {
      content: none;
      position: absolute;
      margin-left: 20px;
      -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      width: 25px;
      height: 25px;
      transform: translateY(-15px) rotate(45deg);
      -webkit-transform: translateY(-15px) rotate(45deg);
      background: var(--color-white);
      display: block;
      top: 10px;
      left: 0;
      z-index: -1;
      border: 1px solid var(--gray-color);
    }

    svg {
      width: 15px;
    }

    span {
      font-weight: normal;
    }

    .password-heading {
      color: var(--black-light-color);
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      color: rgba(0, 0, 0, 0.87);
      padding: 10px 15px;
      position: relative;
      line-height: 1.8;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 15px;
        background: var(--gray-color);
        height: 1px;
        width: calc(100% - 30px);
      }
    }

    .main-e-box {
      .tooltip-error-text-container {
        padding: 3px 0;
        display: flex;
        display: -webkit-flex;
        align-items: center;
      }

      span {
        font-size: 14px;
      }
    }
  }
}

.location-container {
  .tooltip {
    .tooltiptext {
      width: calc(100% + 30px);
    }
  }

  .error {
    ~ {
      .tooltip {
        .tooltiptext {
          margin-top: -6px;
        }
      }
    }
  }
}

/* Target Tracking Page Css Start */
.cus-main-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  height: calc(100vh - 154px);

  .cus-inner-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .page-heading {
      width: 100%;
      display: inline-block;
      text-align: center;
      padding: 10px 0;

      h2 {
        font-weight: bold;
        font-size: 40px;
        color: var(--text-main);
      }
    }

    .grid-heading {
      width: 100%;
      display: inline-block;
      text-align: center;
      margin-bottom: 7px;

      h3 {
        font-size: 35px;
        font-weight: bold;
      }
    }

    .cus-circle-chart {
      width: 100%;
      display: inline-block;
    }

    .cus-per-sec {
      width: 100%;
      display: table;
      height: 270px;
      align-items: center;
      position: relative;
      padding: 0 10px;

      .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        list-style: none;
        height: 100%;
        align-items: center;
        justify-content: center;

        .row {
          display: inline-block;
          width: 100%;
          position: relative;
          margin: 10px 0;

          h3 {
            font-weight: bold;
            font-size: 30px;
            color: var(--brown-color);
            padding-left: 60px;

            span {
              font-weight: bold;
            }
          }

          &:after {
            content: "";
            height: 30px;
            width: 30px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 50%;
            -ms-transform: translate(0, -50%);
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
            transform: translate(0, -50%);
            background-color: var(--primary-color);
          }

          &.dot-1 {
            &:after {
              background-color: var(--primary-color);
            }
          }

          &.dot-2 {
            &:after {
              background-color: var(--primary-color);
            }
          }

          &.dot-3 {
            &:after {
              background-color: var(--primary-color);
            }
          }
        }
      }
    }

    .left-card {
      padding-right: 15px;

      .cus-per-sec {
        .info {
          .row {
            h3 {
              padding-left: 0;
              padding-right: 60px;
              text-align: right;
            }

            &::after {
              left: auto;
              right: 0;
            }
          }
        }
      }
    }

    .right-card {
      padding-left: 15px;
    }

    .cus-date-sec {
      position: absolute;
      right: 20px;
      top: 35px;

      h3 {
        font-weight: bold;
        font-size: 18px;
        color: var(--brown-color);
      }
    }

    .cus-time-sec {
      position: absolute;
      right: 20px;
      top: 35px;

      h3 {
        font-weight: bold;
        font-size: 18px;
        color: var(--brown-color);

        > div {
          color: var(--brown-color) !important;
          width: auto !important;
          display: inline-block;
          text-transform: uppercase;

          span {
            min-width: 95px;
            display: inline-block;
            padding: 0 !important;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.cus-inner-container {
  .cus-middle-sec {
    height: calc(100% - 100px);
  }

  .cus-grid-rep {
    border: 3px solid var(--brown-color);
    border-top: none;
    height: 50%;

    &:first-child {
      border-left: none;
      border-right: none;
    }

    &:last-child,
    &:nth-child(2) {
      border-right: none;
    }

    &:nth-child(3) {
      border-right: none;
      border-left: none;
      border-bottom: none;
    }

    &:nth-child(4) {
      border-bottom: none;
    }

    &:nth-child(5) {
      border-bottom: none;
    }
  }
}

.part {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 28px 0;
  flex-direction: column;
  height: 100%;

  h3 {
    color: var(--primary-color);
  }

  .count {
    font-size: 50px;
    color: #4c6072;
    font-weight: 900;
  }
}

.analytics-box {
  background-color: var(--color-white);
  color: var(--black-color);

  .info-icon {
    color: var(--grey-white);
  }

  .counts {
    h2 {
      font-size: 40px;
      font-weight: 600 !important;
    }

    h5 {
      font-size: 24px;
      font-weight: 600 !important;
    }
  }

  h2 {
    font-size: 20px;
    color: #8767ff;

    span {
      line-height: normal;
      padding: 20px 0;

      small {
        font-size: 12px;
        display: block;
        color: $light_grey;
      }
    }

    .border-span {
      border-left: 1px solid var(--header-border);
      padding-left: 20px;
      margin-left: 20px;
      line-height: normal;
    }
  }

  .title {
    font-size: 14px;
    color: var(--black-color);
    font-weight: 600;
  }

  .filterOption {
    background: $light_grey;
    border-radius: 50px;
    border-radius: 50px;
    width: 32px;
    height: 32px;

    p {
      color: var(--black-color);
      font-weight: normal;
      opacity: 1;
    }

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: $light_grey;
      }
    }
  }

  span {
    &.count-detail {
      display: inline-block;
      font-size: 12px;
      letter-spacing: 0px;
      line-height: normal;
      margin: 2px 0 8px;
      width: 100%;
    }
  }

  p {
    font-size: 14px;
    color: var(--grey-white);
  }

  .active-filter {
    &.filterOption {
      background-color: #05a88e29;

      svg#filter path {
        fill: #05a88e;
      }
    }
  }

  .filter-value {
    padding: 5px 17px;
    border-radius: 20px;
    border: 1px solid #05a88e;

    p {
      color: #05a88e;
    }
  }
}

.device-box {
  width: 20%;
  padding: 0 10px 20px;

  > div {
    height: 200px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    text-align: center;
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 20px;
    color: var(--black-color);

    .d-flex {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;

      span {
        font-size: 14px;
        font-weight: 600;
        color: var(--black-color);

        img {
          margin-right: 6px;
        }
      }

      span + img {
        cursor: pointer;
      }
    }

    h2 {
      font-size: 32px;
      text-align: center;
      color: var(--black-color);
      display: flex;
      padding-top: 16px;
      justify-content: center;
      width: 100%;

      span {
        line-height: normal;
        padding: 20px 0;

        small {
          font-size: 12px;
          display: block;
          color: $light_grey;
        }
      }

      .border-span {
        border-left: 1px solid var(--header-border);
        padding-left: 20px;
        margin-left: 20px;
        line-height: normal;
      }
    }

    p {
      font-weight: 600;
      font-size: 20px;
    }
  }

  &:first-child {
    padding-left: 0px;
  }

  &:last-child {
    padding-right: 0px;
  }
}

.apexcharts-yaxis-label {
  margin-right: 10px;
}

.progress {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  margin: 15px 0;
  height: 6px;
  max-width: 300px;
}

.progress-done {
  background: #ffb400a1;
  border-radius: 20px;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

.rating-container {
  min-width: 500px;
  max-width: 600px;
  margin: 20px auto;

  .left-side-card {
    width: 35%;
    text-align: center;
    margin-top: 6px;

    .user-review-text {
      font-weight: 900;
      font-size: 20px;
    }

    .rating-text {
      font-size: 40px;
      margin-top: 18px;
      font-weight: 800;
    }

    .review-text {
      font-weight: 700;
      font-size: 16px;
      margin-top: 4px;
    }

    label {
      font-size: 22px;
    }

    p {
      font-size: 18px;
    }
  }

  .right-side-card {
    width: 65%;
    margin: 20px 10px 0px 30px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 10px 0px;

        span {
          width: 20px;
          display: flex;
        }

        .progress {
          background-color: #d8d8d8;
          border-radius: 20px;
          margin: 0;
          height: 6px;
          display: inline-block;
          width: 70%;
        }

        .progress-done {
          background: #ffb400a1;
          border-radius: 20px;
          color: #e5f1d3;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          transition: 1s ease 0.3s;
        }
      }
    }
  }
}

.clear-btn {
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  padding: 0px 30px 0px 30px;
  font-size: 18px;
  color: white;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 500;
  margin-right: 10px;

  span {
    font-size: 14px;
    font-weight: bold;
  }
}

.modal-title {
  .close {
    display: flex;
    justify-content: flex-end;
  }
}

.add-ota-container {
  display: flex;
  justify-content: center;

  h1 {
    margin-bottom: 22px !important;
  }

  .dialog-bottom-btns {
    padding-top: 20px;
  }
}

.table-custom-width {
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
    background: var(--color-white);
  }

  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 10px) scale(1) !important;
    pointer-events: none;
  }

  .ota-select {
    .MuiSelect-select {
      width: 80px;
    }
  }
}

.date-picker {
  .DateRangePicker {
    min-width: 490px;
  }
}

.rating-tooltip {
  position: relative;
  display: inline-block;
  margin-left: 60px;
  width: 100%;

  .rating-tooltiptext {
    background-image: url("../image/passwordCard.png");
    background-size: 100% 100%;
    margin-top: -15px;
    width: 100%;
    border-radius: 6px;
    position: absolute;
    z-index: 999;
    top: 150%;
    left: 0;
    margin-left: -75px;
    visibility: visible;
    display: block;
    padding: 25px 15px 10px 15px;

    &::after {
      content: none;
      position: absolute;
      margin-left: 20px;
      -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      width: 25px;
      height: 25px;
      transform: translateY(-15px) rotate(45deg);
      -webkit-transform: translateY(-15px) rotate(45deg);
      background: var(--color-white);
      display: block;
      top: 10px;
      left: 0;
      z-index: -1;
      border: 1px solid #d3d3d3;
    }

    svg {
      width: 15px;
    }

    span {
      font-weight: normal;
    }
  }
}

.replace-device-color {
  background-color: #80808047;
}

.csv-download-container {
  display: flex;
  justify-content: space-around;

  .partition-line {
    width: 2px;
    height: 30px;
    background-color: var(--primary-color);
  }
}

.force-sync-datepicker {
  padding-top: 4px !important;

  .MuiFormControl-marginNormal {
    margin-top: 0px !important;
    margin-bottom: 0px;
  }
}

.trial {
  width: 30px !important;
  color: var(--success) !important;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
}

.trial-no {
  width: 30px !important;
  color: var(--error-red-color);
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
}

.history-button {
  font-weight: bold;
  background-color: var(--primary-color);
  color: var(--white);
  text-decoration-line: none;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  min-width: 100px;
  text-align: center;
  margin-left: 5px;
  cursor: pointer !important;

  &.trial {
    width: 30px !important;
    color: #008000;
  }

  &.trial-no {
    width: 30px !important;
    background-color: var(--error-red-color) !important;
    color: red;
  }
}

.MuiPickersModal-dialog {
  .MuiPickersDay-daySelected:hover {
    background-color: $primary-color !important;
  }
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary:hover {
  background-color: #fff !important;
}

label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementTop {
  margin: 0 0 20px !important;
  align-items: center !important;
}

.sidebar-menus {
  .device-heading {
    padding: 8px 0 10px 10px;
    font-size: 18px;
    font-weight: 600;
    color: var(--secondary-color);
  }

  .device-list {
    list-style: none;
    padding-left: 8px;
    font-family: "Inter";
    font-weight: 600;
    color: var(--secondary-color);

    li {
      font-size: 16px;
      padding: 10px 0px 16px 0;
    }

    .selected-product {
      opacity: 1;
      background-color: var(--transparent-color);
    }
  }

  .product-divider {
    background-color: #4c6072;
    height: 2px;
    margin: 10px 0 20px 0;
  }
}

.drop-down-custom.bg-white.boxshadow-custom {
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 6px;
  }
}

.goto-pagination {
  .MuiOutlinedInput-input {
    padding: 16px !important;
    font-size: 14px;

    svg {
      color: var(--white);
    }
  }
}

.bg-transparent {
  > div {
    background-color: transparent !important;
  }
}

.cus-tabs {
  padding-top: 14px;

  button {
    height: 40px;
    min-width: auto;
    background: transparent;
    border-radius: 5px;
    padding: 0 14px;
    min-height: auto;
    margin-right: 10px;
    text-transform: capitalize;

    span {
      &.MuiTab-wrapper {
        color: var(--grey-color);
      }
    }

    &.Mui-selected {
      background: var(--grey-color) 0% 0% no-repeat padding-box;

      span {
        &.MuiTab-wrapper {
          color: var(--white);
        }
      }
    }
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabs-flexContainer {
    padding: 0;
  }
}

.box-style {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid var(--header-border);
  border-radius: 4px;

  li {
    padding: 48px 16px;
    border: 1px solid var(--header-border);
    width: 50%;
    display: inline-block;
    border-top: 0;
    border-left: 0;
    text-align: center;

    .badge-text {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 60px 0 20px;

      .text-style {
        color: var(--black-color);
        font-size: 30px;
        font-weight: 600;
        letter-spacing: -1px;
        line-height: normal;
      }

      .badge {
        color: var(--black-color);
        border-radius: 15px;
        opacity: 1;
        text-align: center;
        display: table;
        padding: 2px 12px;
        font-weight: 600;
      }
    }

    p {
      font-size: 13px;
      color: $light_grey;
      font-weight: 600;
    }

    &:last-child {
      border-bottom: 0;
      border-right: 0;
    }

    &:nth-child(2) {
      border-right: 0;
    }
  }
}

.csv-download-container {
  display: flex;
  justify-content: space-between;

  .partition-line {
    width: 2px;
    height: 55px;
    background-color: #ff0000;
  }
}

.rs-anim-fade.rs-anim-in {
  opacity: 1;
  pointer-events: unset;
  z-index: 9999;
}

* body #menu- {
  z-index: 9999 !important;
}

.select-input-custom > div {
  background: transparent;
  box-shadow: none !important;
  border-color: var(--border-color);

  .css-b8ldur-Input {
    color: var(--color-black);
  }

  &:hover {
    border-color: var(--border-color) !important;
  }
}

.customer-csv {
  .download-container {
    margin: 0 50px;

    .csv-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid var(--black-color);
      padding: 8px 14px;
      margin-bottom: 15px;
      border-radius: 6px;
      color: var(--black-color);

      .download-icon {
        padding: 2px;
        border-radius: 5px;
        background: var(--primary-color);
        cursor: pointer;

        img {
          padding: 2px;
        }
      }
    }
  }
}

.select-custom {
  .MuiSelect-select {
    padding: 14px;
    color: var(--white) !important;
  }
}

.device-detail {
  .select-custom {
    .MuiSelect-select {
      padding: 14px;
      color: var(--black-color) !important;
      text-align: left;
    }
  }
}

/** login Screen Scss Here **/
.login-custom-width {
  background: url("../../assets/image/background-img.png");
  height: 100vh;
  background-position: center center;
  background-size: cover;

  .logo-wrapper {
    margin: 10px 0 40px;
    text-align: center;

    img {
      margin: 0 auto;
      width: 150px;
      height: 100px;
    }
  }

  h2 {
    text-align: center;
    color: var(--white);
    font-size: 28px;
    font-family: "Inter" !important;
    font-weight: 600 !important;
    text-transform: uppercase;
  }

  .login-form {
    width: 100%;
    justify-content: flex-end;
    height: 100vh;
    display: flex;
    align-items: flex-end;

    form {
      &.full-w {
        max-width: 650px;
        min-width: 620px;
        margin: 0 auto;
        padding: 30px 80px 10px;
        background: #1b2439 0% 0% no-repeat padding-box;
        border-radius: 10px 10px 0px 0px;
        max-height: calc(100vh - 20px);
        overflow: auto;

        &.custom-width {
          width: 100%;

          input {
            height: 42px;
            padding: 0 14px;
          }

          .remember-me {
            label {
              margin: 0;
              text-align: left;

              > span {
                font-size: 14px;
                color: var(--grey-white);
                padding: 0 0;
                margin-right: 6px;
              }
            }

            &.label-password {
              justify-content: space-between;
              display: flex;
              align-items: center;
              margin: 0 0 16px;
            }
          }

          .sign-in-btn {
            width: 180px;
            font-size: 18px;
            margin: 24px 0;
            height: 42px;
          }

          .terms {
            letter-spacing: 0px;
            color: var(--grey-white);
            margin: 40px 0 0;
            font-size: 13px;
          }
        }

        .MuiOutlinedInput-input {
          padding: 14px;
          color: var(--white);

          svg {
            color: var(--white);
          }
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: var(--grey-white) !important;
        }
      }

      label {
        color: var(--grey-white);
      }

      svg {
        path {
          fill: var(--grey-white);
        }
      }

      .password-field {
        span.help-block {
          &.error.text-left {
            bottom: 0;
            position: relative;
            color: var(--error-red-color) !important;
          }
        }
      }

      .checkbox-terms {
        font-size: 14px;
        color: var(--white);
        margin-top: 40px;

        label {
          margin: 0 4px 0 0;
          padding: 0;

          span {
            padding: 0;
          }
        }

        a {
          color: var(--primary-color);
        }
      }

      .button-btn {
        &.btn-custom-primary {
          &:hover {
            color: var(--primary-color);
            background: var(--white) !important;
            border-color: transparent;
          }
        }
      }
    }
  }
}

.login-forgot-password {
  cursor: pointer;
  display: inline-block;
  color: var(--grey-white);
  width: 100%;
  font-size: 13px;
  text-align: right;

  &:hover {
    color: var(--primary-color);
  }
}

/** Login Screen Scss End **/

.safelink-firmware-error {
  color: #ff0000;
  display: flex;
  justify-content: start;
  width: 100%;
}

.tableMoreInfo {
  font-size: 15px;
  color: #000;
  background-color: "$white_color";
  padding: 10px;
  word-break: "break-word";
  max-width: 400px;
}

// Analytics Styles
.filter {
  background: #2a3347;
  width: 300px;

  .filter-title {
    color: $white_color;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light_grey;
    padding: 14px;

    span,
    h6 {
      color: $white_color;
    }
  }

  .version-filter {
    .MuiFormControlLabel-label {
      font-size: 13px;
    }

    .MuiSvgIcon-root {
      font-size: 20px;
    }

    .MuiCheckbox-root {
      color: #8493a7;
    }

    .MuiCheckbox-colorSecondary {
      &.Mui-checked {
        color: $primary-color;
      }
    }
  }

  .MuiCheckbox-colorSecondary {
    &.Mui-checked {
      color: $primary-color;
    }
  }

  .date-filter {
    .MuiFormControlLabel-label {
      font-size: 13px;
    }

    .MuiRadio-root {
      color: #8493a7 !important;
    }

    .MuiIconButton-colorSecondary {
      color: #8493a7 !important;
    }

    .MuiIconButton-colorSecondary {
      &.Mui-checked {
        color: $primary-color !important;
      }
    }
  }

  .MuiFormControlLabel-label {
    color: $white_color !important;
  }

  input[type="checkbox"] + svg path {
    fill: #c5c6c7;
  }

  .version-filter {
    .Mui-checked {
      input[type="checkbox"] + svg path {
        fill: $primary-color !important;
      }
    }
  }

  .rs-picker-toggle-wrapper {
    padding: 0 20px;
  }
}

.otpContainer {
  margin: 5% auto;
  background: #1b2439;
  height: 500px;
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .button-btn {
    &.btn-custom-primary {
      &:hover {
        color: var(--primary-color);
        background: var(--white) !important;
        border-color: transparent;
      }
    }
  }
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 1.7rem;
  text-align: center;
  border-radius: 4px;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 12px;
  font-weight: 600;
}

.text-resend {
  color: var(--primary-color);
  font-size: 16px;
}

.range-filter-select {
  .MuiInputLabel-outlined {
    color: var(--gray-color);
  }
}

.box-item {
  flex: 1 1 auto;
}

// For Heatpump Board CSS Start
.heat_pump {
  .heatdb-p0 {
    padding: 0;

    button.filter-btn-style {
      top: 89px;
      width: 48px !important;
      height: 44px !important;
    }
  }

  .residential {
    color: #008080 !important;
    font-weight: 600 !important;
  }

  .commercial {
    color: #d3551d !important;
    font-weight: 600 !important;
  }

  .heat_pump_divider {
    border-bottom: 2px solid $light_grey;
    opacity: 0.2;
    margin: 8px 0;
  }

  .custom-menu {
    > .MuiDrawer-paperAnchorDockedLeft {
      justify-content: space-between;
      background: $mostly_black;
      overflow: hidden;
      border-right: 1px solid #dfe5ec;
    }

    .sidebar-menus {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      .sidepanel-item {
        margin: 0;
        height: 40px;
        padding: 8px 12px;
        border-radius: 6px;
        &:hover {
          span {
            color: var(--Gray-100, #f2f4f7);
          }
        }
        &.active {
          opacity: 1;
          background-color: rgba(0, 168, 142, 0.16);
        }
        .sidebar-menu-text {
          span {
            font-size: 14px;
            color: var(--Gray-100, #f2f4f7);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
          }
        }

        .sidebar-menu-text.active {
          span {
            color: var(--Gray-100, #f2f4f7);
          }
        }

        .sidebar-menu-icon {
          svg {
            width: 22px;
            height: 22px;
          }

          img {
            width: 22px;
            height: 22px;
          }
        }

        .active {
          img {
            padding: 1px;
            width: 26px;
            height: 26px;
            filter: unset;
            transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -ms-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
          }
        }
      }
    }

    .d-block {
      display: block;
    }
  }

  h3 {
    color: $mostly_black !important;
    font-family: Inter;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 120% !important;
  }

  .search-add {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .MuiIconButton-label {
      color: $primary-color;
    }

    button {
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      transition: all 0.5s ease;

      &:hover > * {
        color: $mostly_black;
        transition: all 0.5s ease;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }

  .history-button {
    font-weight: 600;
    background-color: #f6f8fa;
    border: 1px solid #00a88e;
    color: #38a88e;
    padding: 4px 7px;
    font-size: 12px;
    min-width: fit-content;
    transition: all 0.5s ease;

    &:hover {
      background-color: #38a88e;
      color: $white_color;
      transition: all 0.5s ease;
    }
  }

  button.filter-btn-style {
    padding: 12px 16px;
    border-radius: 4px;

    img {
      width: 14px;
      height: 14px;
    }
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #f9fafb;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 168, 142, 0.16);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $mostly_black;
  }

  .MuiTableContainer-root {
    overflow-x: unset;
  }

  .demo-select {
    min-width: 90px;
    border-radius: 4px;
    background-color: #f0f1f3;
  }

  .custom-table-pagination {
    .pagination-div {
      .MuiPagination-ul {
        .MuiPaginationItem-page {
          &:hover {
            background-color: rgba(0, 168, 142, 0.16);
            transition: all 0.5s ease;
          }

          border-radius: 50px;
          transition: all 0.5s ease;
          min-width: 28px;
          height: 28px;
        }
      }
    }
  }

  main {
    .table-program {
      th {
        background-color: $white_color !important;
        color: $light_grey;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        min-width: max-content;

        p {
          color: $light_grey;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
      }

      td {
        color: $mostly_black;
        min-width: max-content;

        .MuiChip-root {
          .MuiChip-label {
            font-weight: 500;
          }
        }

        .MuiChip-colorPrimary {
          background-color: #e8f8f1;
          .MuiChip-label {
            color: #12b76a;
          }
        }

        .MuiChip-colorError {
          background-color: #ffecf0;
          .MuiChip-label {
            color: #f63d68;
          }
        }
      }
    }
  }

  .table-pagination {
    .MuiTablePagination-toolbar {
      min-height: 100%;
      padding: 0;
      border-top: 0;
    }
  }

  .button-btn {
    font-size: 14px;
    padding: 10px 24px;
    min-width: fit-content;
    min-height: auto;
    text-transform: capitalize;
    font-weight: 600;
    border-radius: 4px !important;
  }

  .badge {
    padding: 4px 8px;
    border-radius: 20px;
    width: fit-content;
    display: inline-block;
    font-size: 14px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 16px;

    &.green {
      background: rgba(18, 183, 106, 0.1);
      color: #12b76a;
    }

    &.red {
      background: rgba(246, 61, 104, 0.1);
      color: #f63d68;
    }

    &.yellow {
      background: rgba(246, 178, 61, 0.1);
      color: var(--yellow) !important;
    }

    &.blue {
      background: #9e9e9e;
      color: #ffffff !important;
    }
  }

  .activity_status {
    .button-btn {
      min-width: 150px;
    }

    .activity-lable-status {
      fieldset {
        legend {
          min-width: 45px;
        }
      }
    }
  }
}

.cus-btn-modal-submit {
  background-color: #00a88e !important;
  color: #fff !important;
  padding: 8px 20px !important;
  border-radius: 30px !important;
  height: 35px;
}

.cus-btn-modal-submit.disabled {
  opacity: 0.3 !important;
  cursor: default !important;
  pointer-events: none !important;
}

.heat_pump_dialog_content_searchbar {
  margin-bottom: 10px !important;
  .MuiFilledInput-root {
    background-color: #f1f5f9 !important;
    font-size: 12px;
    line-height: 14px;
    border-radius: 8px;
  }
  .MuiFilledInput-input {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 0px !important;
    padding-right: 10px !important;
    color: #94a3b8 !important;
  }
  .MuiFilledInput-adornedStart {
    padding-left: 10px;
  }
  .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-top: 0px !important;
  }
  img {
    height: 12px !important;
    width: 12px !important;
  }
  svg {
    color: #94a3b8 !important;
    height: 12px !important;
    width: 12px !important;
  }

  .MuiFilledInput-underline:before {
    content: "" !important;
    border-bottom: none !important;
  }

  .MuiFilledInput-underline:after {
    content: "" !important;
    border-bottom: none !important;
  }

  .MuiFilledInput-underline:hover:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.87);
  }
}

.heat_pump_dialog_content_list {
  .MuiListItem-gutters {
    padding: 12px 18px;
    .MuiListItemText-root {
      margin: 0;
      padding-right: 10px;
      .MuiTypography-body2 {
        p {
          margin-bottom: 4px !important;
          font-size: 14px;
          line-height: 14px;
          color: #0f172a;
          font-weight: 500;
        }
        span {
          color: #667085;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
}

.MuiDialogTitle-root {
  padding: 22px !important;

  h6 {
    font-weight: bold;
  }

  .cus-btn-modal-head {
    color: #00a88e;
    font-size: 12px;
    line-height: 20px;
    padding: 4px 8px;
    text-transform: capitalize;
    border: 1px solid #00a88e;

    .MuiButton-startIcon {
      margin: 0 !important;
      padding: 4px;
      margin-right: 3px !important;
      img {
        height: 12px;
        width: 12px;
      }
      svg {
        height: 12px;
        width: 12px;
      }
    }
  }
}

.heat_pump_dialog_action {
  padding: 17px 24px !important;
  button {
    &.cus-btn-modal-submit {
      text-transform: capitalize;
      min-width: 100px;
    }
  }
}

.heat_pump {
  .search-add button.btn-custom-primary:hover > * {
    color: #ffffff;
  }
  #no-more-tables {
    border-radius: 8px;
    border: 1px solid #eaecf0;
  }
}

.heat_pump_text_color {
  color: #0f172a;
}

.heat_pump {
  .location-head {
    .heading {
      h2 {
        font-size: 32px;
        color: #0f172a;
        font-weight: 600 !important;
      }
    }
  }

  .MuiTableRow-head {
    .MuiTableCell-paddingCheckbox {
      .MuiCheckbox-root {
        visibility: hidden;
      }
    }
  }
}

.heatpump_dropdownloader {
  margin-right: -30px;
}

.MuiAutocomplete-popper {
  top: 2px !important;
  border-radius: 6px;
  box-shadow: 0px 1px 1px 2px #bdbcbc;
  .MuiAutocomplete-paper {
    margin: 0px 0;
    border-radius: 10px;
    .MuiAutocomplete-option {
      padding: 9px 16px;
    }
  }
}

.heat_pump_dialog_content {
  // padding: 22px 22px 38px 22px !important;
  .MuiPaper-rounded {
    border-radius: 10px !important;
    .listItemSelected {
      background-color: #eaecf0;
    }
  }
  .MuiFilledInput-underline {
    ::before {
      content: "" !important;
    }
  }

  .MuiListItemSecondaryAction-root {
    display: flex;
  }

  input {
    &.heat_pump_checkbox[disabled] {
      cursor: not-allowed !important;
    }

    &.heat_pump_checkbox[type="checkbox"] {
      -webkit-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      border: 1px solid #435567;
      border-radius: 4px;
      background-color: #fff;
      cursor: pointer;
    }

    &.heat_pump_checkbox[type="checkbox"]:checked {
      background-color: #00a88e;
      position: relative;
      background-image: url("../image/checkbox.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 11px;
      border: none;
    }
  }
}

.no-data-modal-text {
  font-weight: bold;
  font-size: 22px;
}

.heat_pump_checkbox_summary {
  display: flex;
  width: min-content;
  gap: 24px;

  .btn-custom-primary {
    height: 40px !important;
    text-transform: capitalize !important;
  }

  .selectionlabel {
    color: #0f172a !important;
    min-width: fit-content !important;
    line-height: 16px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  .selectionlabelBtn {
    .MuiButton-label {
      .heat_pump_checkbox {
        margin-right: 10px;
      }
    }
    min-width: fit-content !important;
    color: #667085 !important;
    line-height: 16px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}

.heat_pump.total_sites,
.heat_pump.total_devices {
  color: #00a88e;
}

.heat_pump.faulty_count {
  color: #5482ab;
}

.heat_pump.online_devices {
  color: #65c466;
}

.heat_pump.imp_offline_devices,
.heat_pump.offline_devices {
  color: #df3939;
}

.btn-custom-clearbtn {
  background-color: #f0f1f3 !important;
  box-shadow: none !important;
  color: #667085 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  font-weight: 500 !important;
  padding: 10px 24px !important;
  min-width: fit-content !important;
}

.heat_pump_modal_title h2 h6 {
  font-size: 18px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  color: #0f172a !important;
  line-height: 22px !important;
}

.inputlabelHeatPumpModal {
  font-size: 14px !important;
  color: #707070 !important;
  font-weight: 400 !important;
  line-height: 16.41px !important;
  margin-bottom: 8px !important;
}

.inputfieldHeatPumpModal {
  .MuiOutlinedInput-input {
    padding: 12.5px;
  }
}

.inputfieldHeatPumpModalRemove {
  .MuiOutlinedInput-input {
    padding: 0px !important;
  }
}

.hpcard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px !important;
  height: 88px;

  .hptitlebox {
    flex: 1 1;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

body {
  .green-btn-theme {
    border-radius: 20px;
    background: $primary-color;
    color: $white_color;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 114.286%;
    box-shadow: none;
    outline: none;
    letter-spacing: normal;
    text-transform: capitalize;
    padding: 4px 8px;

    &:hover {
      box-shadow: none;
      background-color: $primary-color;
      opacity: 0.7;
    }
  }

  .dbtitle {
    color: $mostly_black;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 4px !important;
    letter-spacing: normal;
  }

  .sumtext {
    color: #3862ee;
    text-align: right;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 125%;
    letter-spacing: -0.64px;
  }

  .total_sites,
  .faulty_count {
    color: #5398ff;
  }

  .online_devices {
    color: #008000;
  }

  .imp_offline_devices,
  .offline_devices {
    color: #f63d68;
  }
}

.material-add-icon {
  line-height: 1;
}

.MuiButton-label {
  &:has(.material-add-icon) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
}

.MuiChip-root.filter-db {
  border-radius: 20px;
  background: rgba(102, 112, 133, 0.1);
  display: inline-flex;
  padding: 4px 8px 4px 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: unset;

  span.MuiChip-label {
    color: #667085;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding: 0;
  }
}

.commtitle {
  color: $mostly_black;
  font-size: 16px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 120%;
}

.cus-point {
  border: none;
  z-index: 1;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: $primary-color;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }

  &:hover:after {
    left: 0;
    width: 100%;
    transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
  }

  &:hover {
    color: var(--black-white) !important;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
  }
}

.searchBar {
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #eaecf0;
  background-color: $white_color;
  padding: 8px;

  .srhIcon {
    position: absolute;
    left: 12px;

    svg {
      width: 16px;
      height: 16px;
      color: $light_grey;
    }
  }

  .MuiInputBase-formControl {
    padding: 0;

    .MuiInputBase-input {
      display: flex;
      padding: 12px 34px 12px;
      border-radius: 4px;
      background-color: #f9fafb;
      color: $light_grey;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 114.286%;
    }

    fieldset.MuiOutlinedInput-notchedOutline {
      border: none;
      outline: none;
    }
  }
}

.ht-titletext {
  width: 100%;
  align-items: center;
  display: flex;
}

.swi-btn {
  display: flex;
  width: fit-content;
  height: 24px;
  padding: 2px 2px 2px 12px;
  align-items: center;
  gap: 5px;
  color: $white_color;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 114.286%;
  border-radius: 30px;
  background: #12b76a;
  cursor: pointer;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.resolved_text {
  background: #12b76a;
}

.unresolved_text {
  background: $light_grey;
}

body {
  &:has(.heat_pump) {
    .sidebar-right-side {
      .MuiDrawer-paperAnchorRight {
        background-color: $white_color;

        .rs-picker-default {
          &:not(.rs-picker-disabled) {
            .rs-picker-toggle {
              &:hover {
                border-color: $light_grey;
              }

              .rs-picker-toggle-value {
                color: $mostly_black;
              }

              .rs-picker-toggle-textbox {
                background-color: #fff;
                border-color: $light_grey;
                border-radius: 6px;
              }
            }

            .rs-picker-toggle-active {
              border-color: $light_grey;
            }
          }
        }

        .header-sidebar-right {
          background: $mostly_black;

          h2 {
            color: $white_color !important;
            font-style: normal;
            font-weight: 600 !important;
          }

          svg {
            color: $white_color;
          }

          + {
            ul {
              li {
                &:has(.buttons-div) {
                  position: fixed;
                  bottom: 0;
                  width: 100%;
                  max-width: 399px;
                }
              }
            }
          }
        }
      }

      .header-sidebar-right {
        + {
          .list-style-list {
            height: 100vh;
            overflow: auto;
            padding: 0;
            min-height: calc(100vh - 70px);
            overflow: auto;
            max-height: calc(100vh - 70px);
            padding-bottom: 100px;

            .MuiAccordion-root:before {
              height: 0;
            }

            &:has(.MuiBackdrop-root) {
              > div {
                z-index: 99;
                position: relative;
              }
            }

            &:has(.Mui-expanded) {
              height: calc(100vh - 170px);
              transition: all 0.5s ease;

              li:has(.buttons-div) {
                position: fixed;
                bottom: 0;
                z-index: 22;
                right: 0;
                width: 400px;

                // .buttons-div {
                //   background: none;
                // }
              }
            }

            &:has(.MuiBackdrop-root) {
              height: 100vh;
            }
          }

          .backdrop-scroll {
            overflow: hidden;
          }

          ul {
            padding-top: 1rem;

            li {
              > .Mui-expanded {
                // padding-top: 8px;
              }

              label {
                .MuiFormControlLabel-label {
                  color: $mostly_black !important;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 150%;
                }
              }

              .custom-search-field {
                input {
                  color: $mostly_black;
                }
              }

              .MuiPaper-root {
                box-shadow: none;

                .MuiAccordionSummary-content {
                  margin: 0;

                  .MuiIconButton-root {
                    padding: 0 9px;
                  }
                }
              }
            }
          }
        }
      }

      .buttons-div {
        gap: 12px;
        display: inline-flex;
        padding: 23px 24px;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        border-radius: 16px 16px 0px 0px;
        background: $admin_background;

        .button-btn {
          border-radius: 30px;
          border: 1px solid $light_grey;
          background: none;
          color: $light_grey;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0 !important;
          padding: 8px 20px;

          &:last-child {
            background: $primary-color;
            color: $white_color;
            border-color: $primary-color;
          }
        }
      }
    }

    .box-style li {
      width: 100%;
    }
  }
}

.cus-dialog {
  &:has(.faulty_system_form) {
    .MuiDialog-paper {
      h2 {
        color: $mostly_black;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        text-transform: unset;
      }

      p {
        color: $mostly_black !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }

      p.error {
        font-size: 14px;
        color: var(--error-red-color) !important;
      }

      .dialog-btns {
        justify-content: flex-end;

        .button-btn {
          border-radius: 50px;
        }
      }

      .MuiDialogActions-root {
        padding: 8px 24px;
      }
    }
  }

  .faulty_system_form {
    display: flex;
    gap: 25px;
    margin-top: 16px;

    label {
      color: $light_grey !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      line-height: 140%;
    }

    fieldset {
      border-color: $light_grey !important;
    }

    .input-group {
      textarea {
        width: 100%;
        min-height: 120px;
        resize: none;
        border-radius: 4px;
        border-color: $light_grey !important;
        padding: 8px;
        font-size: 16px;
        overflow: auto !important;
        max-height: 150px;
      }
    }
  }
}

@keyframes pindrop {
  0% {
    animation-timing-function: ease-in;
    opacity: 1;
    transform: translateY(-10px);
  }

  24% {
    opacity: 1;
  }

  40% {
    animation-timing-function: ease-in;
    transform: translateY(-14px);
  }

  65% {
    animation-timing-function: ease-in;
    transform: translateY(-8px);
  }

  82% {
    animation-timing-function: ease-in;
    transform: translateY(-4px);
  }

  93% {
    animation-timing-function: ease-in;
    transform: translateY(-2px);
  }

  100% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateY(0px);
  }
}

.marker {
  cursor: pointer;

  .tooltip {
    margin: 3px;
    background-color: $admin_background;
    min-height: 50px;
    min-width: 220px;
    max-height: 150px;
    overflow: auto;
    border-radius: 4px;
    padding: 8px;
    z-index: 9999;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;

    p {
      font-size: 14px;
      line-height: 120%;
      margin-bottom: 4px !important;
      text-transform: capitalize;
    }

    &:hover {
      background: #fff;
      transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
    }
  }

  &:hover {
    img {
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
      transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
    }
  }
}

.device_wifi_signal {
  display: flex;
  gap: 8px;
  justify-content: end;
  padding-right: 20px;

  p {
    max-width: 50px;
  }

  img {
    max-width: 20px;
  }
}

.alert-msg {
  .MuiAlert-standardWarning {
    .MuiAlert-icon {
      font-size: 36px !important;
      align-items: center;
    }
  }

  .alert_btn {
    cursor: pointer;
    background-color: transparent;
    padding: 0;
  }
}

.agents-login-ui {
  .button-btn {
    text-transform: capitalize;
  }

  .search-add {
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    button.filter-btn-style {
      padding: 12px 16px;
      border-radius: 4px;

      img {
        width: 14px;
        height: 14px;
      }
    }
  }
}

.heatdb-p1 {
  padding: 0;

  button.filter-btn-style {
    top: 89px;
    width: 48px !important;
    height: 44px !important;
  }
}

.inner-logs {
  width: 100%;
  background: #f6f8fa;
  display: flex;
  padding: 20px;
  box-shadow: var(--boxShadow-custom) !important;
  -moz-box-shadow: var(--boxShadow-custom) !important;
  -webkit-box-shadow: var(--boxShadow-custom) !important;
  border-radius: 4px;
  align-items: flex-start;
  gap: 20px;

  .logs-rep {
    flex: 1;

    label,
    p {
      width: 100%;
      display: inline-block;
    }

    label {
      margin-bottom: 4px;
      color: #9d9d9d;
      font-weight: normal;
    }

    p {
      color: var(--black);
    }
  }
}

.device-accordion-wrapper {
  .MuiAccordion-root {
    border-radius: 0;
    box-shadow: none;
    margin: 0 0 6px 0 !important;

    &:before {
      content: none;
    }

    .MuiCollapse-container {
      border: 1px solid #f6f8fa;
      max-height: 400px;
      overflow: auto;

      .MuiAccordionDetails-root {
        padding: 12px;
      }
    }

    .MuiAccordionSummary-content {
      margin: 20px 0;
    }

    .MuiAccordionSummary-root {
      background: #f6f8fa;
      border-radius: 4px;
      margin-bottom: 0;
      min-height: auto;

      &.Mui-expanded {
        background: var(--black);
        border-radius: 4px 4px 0 0;

        p {
          color: var(--white);
        }

        svg {
          fill: var(--white);
        }
      }
    }
  }
}

.activity-timeline-sec {
  .timeline {
    border-left: none;
    border-radius: 0;
    padding: 0;

    .event {
      margin: 0;

      &:after,
      &:before {
        content: none;
      }

      .event-bg {
        padding: 20px 12px;
        background: #f6f8fa;
        border-radius: 4px;

        label,
        p {
          width: 100%;
          display: inline-block;
        }

        label {
          margin-bottom: 4px;
          color: #9d9d9d;
          font-weight: normal;
        }

        p {
          color: var(--black);

          &.activity-status {
            background: #e9f5f3;
            color: $primary-color;
            text-align: center;
            border-radius: 40px;
            font-size: 14px;
            font-weight: bold;
            padding: 10px 15px;
            min-width: 70px;
            width: auto;
            line-height: normal;
          }

          &.orange-badge {
            background: #fee9cc;
            color: #de9836;
          }

          &.green-badge {
            background: #cbeee8;
            color: #0ea28a;
          }

          &.green-off-badge {
            background: #e9f5f3;
            color: #95c9bd;
          }

          &.blue-badge {
            background: #dde6ef;
            color: #5582ab;
          }

          &.red-badge {
            background: #fbd8d8;
            color: #ee3d3d;
          }
        }
      }
    }
  }
}

.activity-timeline-sec {
  .device-flash {
    .DateRangePickerInput {
      height: 48px;
    }
  }

  .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu {
    min-width: 120px;
  }
}

.new_fault_icon {
  float: right;

  img {
    width: 20px;
    height: 20px;
  }
}

/* Multi Site Modal Css Start */
.cus-dialog {
  &.multisite-modal {
    .MuiDialog-paperFullWidth {
      max-width: 800px;
    }
    .MuiDialogTitle-root {
      .multi-site-head {
        font-size: 18px;
        text-transform: none;
        font-weight: 600;
        color: $mostly_black;
      }
    }
    &.add-multi-company-modal {
      fieldset {
        border-color: #dcdfe0 !important;
      }
      .MuiAutocomplete-root {
        .MuiAutocomplete-inputRoot {
          min-height: 44px;
        }
      }
    }
  }
}
/* Multi Site Modal Css Ends */

/* Multi Site Companies Css Start */
.cus-multi-site-companies {
  .location-head {
    .search-add {
      button {
        padding: 4px 8px;
      }
    }
  }
}
/* Multi Site Companies Css Ends */

/* Account Admin Email Modal Css */
.account-admin-email-modal {
  z-index: 9999 !important;
}
/* Account Admin Email Modal Ends */

.totalConsumtionsRow {
  margin-bottom: 6px;
  padding: 20px 16px;
  background-color: #02a88e7d;
  border-radius: 4px;
}

.features-table {
  width: 100%;
  display: inline-block;
}
.features-table table {
  width: 100%;
  border-collapse: collapse;
}
.features-table table th,
.features-table table td {
  border: 1px solid #3d3d3d;
  padding: 12px;
  text-align: left;
}
.features-table table th {
  background-color: #34a88e;
  color: #ffffff;
}
.features-table table td:first-child {
  background-color: #bde3dc;
}
.featureTabelHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-big svg {
  height: 24px;
  width: 24px;
}

.ms-1 {
  margin-left: 0.25rem;
}

.ms-2 {
  margin-left: 0.5rem;
}

.selectBox {
  width: 100%;
}

.short-info {
  font-size: 12px;
  text-transform: none;
  text-align: left !important;
  font-weight: normal;
  margin: 2px 0 4px !important;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 6px 10px;
}