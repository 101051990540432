.d-block {
  display: block;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex !important;
}

.bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.cus-pointer {
  cursor: pointer;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-right {
  justify-content: right;
}

.align-items-center {
  align-items: center !important;
}

.m-0 {
  margin: 0 !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.my-5 {
  margin-bottom: 3rem !important;
  margin-top: 3rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.my-4 {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.my-3 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.my-2 {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.my-1 {
  margin-bottom: 0.25rem !important;
  margin-top: 0.25rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.pb-5 {
  padding-bottom: 20px !important;
}

.py-5 {
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
}

.pt-5 {
  padding-top: 20px !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.py-4 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.py-3 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.pr-3 {
  padding-right: 15px !important;
}

.px-3 {
  padding-right: 3rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.py-2 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.py-1 {
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.p-4 {
  padding: 40px !important;
}

.p-3 {
  padding: 30px !important;
}

.p-2 {
  padding: 20px !important;
}

.p-1 {
  padding: 10px !important;
}

.pd-4 {
  padding: 4px;
}

.border-radius-4 {
  border-radius: 4px;
}

.ml-5 {
  margin-left: 3rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.align-center {
  align-items: center;
}

.con-space-between {
  justify-content: space-between;
}

.con-space-around {
  justify-content: space-around;
}

.con-flex-end {
  justify-content: flex-end;
}

.flex-dir-column {
  flex-direction: column;
}

.con-center {
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-0 {
  gap: 5px;
}

.gap-1 {
  gap: 10px;
}

.gap-2 {
  gap: 20px;
}

.gap-3 {
  gap: 30px;
}

.box-shadow-none {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.border-radius-none {
  border-radius: 0 !important;
}

.border-none {
  border: none !important;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-33 {
  width: 33.33%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 12px !important;
}

.p-2 {
  padding: 24px !important;
}

.px-2 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.px-1 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.p-5 {
  padding: 10px !important;
}

.p-24 {
  padding: 24px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pull-right {
  text-align: right;
}

.border-radius-20 {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.border-radius-10 {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.container-spacing {
  padding: 60px;
}

.container-spacing-50 {
  padding: 50px;
}

.full-width {
  width: 100%;
  float: left;
}

.j-center {
  justify-content: center;
}

.heading {
  font-weight: bold;
  color: #808080;
}

.lightBordered {
  border: 1px solid var(--white);
}

.half-width {
  width: 50%;
  float: left;
}

.third-width {
  width: 33.33%;
  float: left;
}

.two-width {
  width: 50%;
  float: left;
}

.checkbox-btn {
  .Mui-disabled {
    color: rgb(148 163 184 / 47%) !important;
  }
}

.MuiFormLabel-root.Mui-disabled {
  color: rgb(148 163 184 / 47%) !important;
}

/* Loading Design Css Start */
.__app_container__ {
  position: relative;

  .__global_loader__ {
    &.showing {
      display: none;
    }

    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    z-index: 99999;
    left: 0;

    div {
      height: 120px;
      width: 120px;

      img {
        height: 100%;
      }
    }
  }
}

.loading-wrapper-custom {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
  left: 0;

  .loader {
    width: 98px;

    img {
      width: 100%;
      object-fit: contain;
    }
  }
}

/* Back Arrow Css Start */
.cus-back-arrow {
  width: 100%;
  display: inline-block;

  img {
    width: 50px;
    height: 25px;
    cursor: pointer;
  }
}

.db-back-arrow-sec {
  margin: 20px 0;
}

/* Back Arrow Css Ends */
label {
  font-size: 14px;
  font-weight: 600;
}

.custom-list-edit {
  align-items: center;
  display: flex;
  padding: 4px 0 0 !important;

  svg {
    cursor: pointer;
  }
}

.select-input-custom {
  >div {
    min-height: 48px;

    .css-xb97g8 {
      color: var(--black);
    }
  }
}

.season-section {
  position: relative;
  width: 98%;
  display: flex;
  flex-direction: column;
  border-top: 0;
  border-radius: 5px;
  margin: 24px auto;
  background: var(--Season-bg);
  color: var(--black-color);

  .Season-start {
    h2.subHeadingColor {
      &.background-theme-color {
        margin: 0 0 20px !important;
        background-color: var(--primary-color);
        color: var(--white);
        font-size: 24px;
        line-height: normal;
        padding: 10px 12px;
      }
    }
  }

  .demo {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 30px;
    margin: 16px 0;
  }

  .custom-flex-add-btn {
    position: absolute;
    right: 18px;
    top: 15px;
    cursor: pointer;

    svg {
      color: var(--color-white);
    }
  }


  .heading-sub-title {
    height: 48px;
    display: flex;
    align-items: center;
    background: var(--white);
    color: var(--black);
    padding: 12px;
    margin-right: 8px;
    border-radius: 4px;
  }

  .MuiSvgIcon-root {
    color: var(--secondary-color);
  }
}

.custom-list-edit .MuiIconButton-label {
  color: var(--secondary-color) !important;
}

.MuiFormControlLabel-label {
  color: var(--secondary-color) !important;
}

.MuiInputAdornment-root span {
  color: var(--text-main) !important;
}

.MuiInputAdornment-root .MuiSwitch-thumb {
  color: #00a88e !important;
}

.input-section-edit {
  span.edit-icon-custom {
    position: absolute;
    right: 20px;
    top: 10px;
    width: 26px;
    height: 26px;
    text-align: center;
    border: 2px solid var(--primary-color);
    border-radius: 4px;

    svg {
      width: 18px;
      fill: var(--primary-color);
    }
  }

  position: relative;
}

.pb-24 {
  padding-bottom: 24px;
  margin-bottom: 0;
}

.d-flex-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-top: 14px !important;

  >label {
    position: absolute;
    top: -14px;
    font-weight: 600;
    color: var(--secondary-color);
    left: 10px;
  }
}

.custom-flex-add-btn {
  position: absolute;
  right: 15px;
  top: -15px;
  cursor: pointer;
}

.flex.justify-end {
  justify-content: flex-end;
  display: flex;
}

.top-bottom {
  margin: 18px 0 !important;
  padding: 19px 15px 10px;

  &.top-0 {
    margin-top: -18px !important;
  }
}

.d-flex-custom {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .drop-down-custom {
    min-width: 220px;
    margin: 0 20px;

    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
      padding-right: 48px;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-white);
    }

    .MuiOutlinedInput-root.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--white-color) !important;
        border-width: 2px;
      }
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      opacity: 0;
    }
  }
}

.tabs-custom-btn {
  button {
    min-width: 200px;
    color: var(--secondary-color);
  }
}

.download-btn-date {
  align-items: center;
  justify-content: flex-end;
  padding-top: 0px;

  .last-date {
    flex-direction: column;
    display: flex;
    margin: 0 12px;
    text-align: left;
    color: var(--secondary-color);
  }
}

.table-custom-width.cus-pointer {
  cursor: pointer;

  &:hover {
    color: var(--primary-color);
    text-decoration: underline;
  }
}

.padding-custom {
  color: var(--black-color);

  label {
    padding: 0 !important;
    display: inline-block;
    color: var(--black-color);
  }

  p {
    padding: 0px 0px 0px !important;
    display: inline-block;
    width: 100%;
    color: var(--black-color);
  }

  .text-color-hover {
    &:hover {
      background: #e2f7f4;
      cursor: pointer;
    }
  }
}

.firware-ver {
  position: relative;
  margin: 0 0 14px !important;

  p.error {
    bottom: -16px;
    z-index: 9999;
    height: auto;
    left: 0;
  }
}

.drop-down-custom {
  .MuiInputLabel-outlined {
    transform: translate(14px, 16px) scale(1) !important;
    -webkit-transform: translate(14px, 16px) scale(1) !important;
    -moz-transform: translate(14px, 16px) scale(1) !important;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
    -webkit-transform: translate(14px, -6px) scale(0.75) !important;
    -moz-transform: translate(14px, -6px) scale(0.75) !important;
    -ms-transform: translate(14px, -6px) scale(0.75) !important;
    -o-transform: translate(14px, -6px) scale(0.75) !important;
  }
}

.green {
  color: var(---success);
  font-weight: 600 !important;
}

.red {
  color: var(--primary-color);
  font-weight: 600 !important;
}

.orange {
  color: var(---yellow);
  font-weight: 600;
}

.location-container-grey {
  .demo-select-test-select label {
    background: var(--color-white);
  }
}

.demo-select-test-select {
  justify-content: flex-end;

  label {
    color: var(--secondary-color);
  }

  .MuiOutlinedInput-input {
    padding: 8px 16px;
    color: var(--black-color);
    font-size: 14px;
  }

  .custom-search-field {
    background-color: transparent !important;
  }
}

.demo-select-test {
  .MuiInputBase-formControl {
    min-height: 36px;
    margin: 0px;
  }

  .filterBy-dropdown {
    .MuiSelect-select {
      cursor: pointer;
      min-width: 140px;
    }
  }

  .MuiSelect-select {
    cursor: pointer;
  }

  .date-picker {
    .MuiInputBase-formControl {
      min-width: 200px !important;
      width: 200px;
    }

    button {
      margin: 0 !important;
      padding: 0;
    }
  }
}

.demo-select {
  border-color: var(--light-grey-color);
  display: flex;
  align-items: flex-start;
  background: var(--light-grey-color);
  -webkit-appearance: none;
  appearance: none;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAR8ICBCvZlaZAAAAb0lEQVQoz73PwQnCQBBA0WchLthFGtMCApqLkBQgVmaErWQ8JEYkozedve37sDv8YRpXJZXioqEK9yQpRqHSiiSZOHRwFEK1W3jrJoT+efGerBhOS5IynOe/TG8PNuu1OjGfPuNXMnxi2Dt845/MA5QCM6UrXXCdAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTAxLTMxVDA4OjA4OjE2KzAwOjAwwCKKggAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wMS0zMVQwODowODoxNiswMDowMLF/Mj4AAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC) no-repeat 82%/11px #f1f1f1;
  padding: 10px 16px;
  min-width: 160px;
  border: none;
  color: var(--black);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.shadow-remove-table {
  .table-design {
    box-shadow: none !important;
  }
}

span.show-pointer {
  svg {
    width: 16px;
    top: 3px;
    position: relative;
    height: auto;
    display: inline-block;
  }
}

.retailer-error {
  p.error {
    position: relative !important;
    text-align: left;
  }
}

input:-internal-autofill-selected {
  background-color: transparent !important;
  appearance: none;
}

// Loader
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  div {
    position: absolute;
    top: 33px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--white);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 8px;
      background-color: var(--primary-color);
      animation: lds-ellipsis1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 8px;
      background-color: var(--primary-color);
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 32px;
      background-color: var(--primary-color);
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 56px;
      background-color: var(--primary-color);
      animation: lds-ellipsis3 0.6s infinite;
    }
  }
}

.device-flash {
  .DateRangePickerInput {
    height: 56px;
  }
}

.apexcharts-legend-series {
  margin: 0 20px 0 0 !important;
}

/** Button Css Here **/
body {
  .button-btn {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 14px;
    padding: 12px 16px;
    text-transform: capitalize;
    background: transparent;
    box-shadow: none;
    min-width: 140px;
    min-height: 42px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Inter";

    &.sign-in-btn {
      text-transform: none;
    }

    &.btn-custom-black {
      color: var(--color-white);
      background: var(--secondary-color);
      border-color: var(--secondary-color);

      &:hover,
      &:active {
        background: var(--primary-color);
        border: none;
        color: var(--color-white);
      }

      &.MuiButton-outlined {
        border: 0;
      }
    }

    &.btn-custom-primary {
      color: var(--white);
      background: var(--primary-color);
      border-color: transparent;
      &.text-transform-none {
        text-transform: none !important;
      }

      &:hover,
      &:active {
        background: var(--secondary-color) !important;
        color: var(--color-white);
        border: none;
      }

      &.MuiButton-outlined {
        border: 0;
      }
    }

    &.btn-custom-border {
      background: var(--primary-color);
      color: var(--color-white);
      border: 2px solid var(--primary-color);
      padding: 3px 16px;

      &:hover {
        border-color: var(--primary-color) !important;
        color: var(--color-white);
      }

      &.add-partner-btn {
        padding: 4px 16px;
        min-width: 160px;
        min-height: 40px;
        font-weight: 400;
        border-radius: 6px;
      }

      &.btn-green {
        border-color: var(--success);
        color: var(--success);
      }

      &.get-zipcodes-btn {
        width: 60%;
        min-width: auto;
        max-width: 140px;
      }

      &.edit-btn {
        min-width: 80px;
      }

      &.download-flash-button {
        &.selected {
          background-color: var(--primary-color);
          color: var(--white);
        }
      }
    }

    &:focus {
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &.button-download {
      color: var(--color-white);
      padding: 0 20px;
      line-height: 48px;
      text-transform: capitalize;
      text-align: center;
      background: var(--danger);
    }
  }
}

.cus-dialog {
  .MuiDialog-paper {
    background: var(--color-white);
  }

  .MuiFormLabel-root {
    color: var(--border-color);
  }

  z-index: 9999 !important;

  h2 {
    text-transform: uppercase;
    font-size: 20px;
    text-align: center;
    // color: var(--secondary);
    color: var(--secondary-color);
  }

  p {
    text-align: center;
    color: var(--secondary-color);
  }

  >div>div {
    border-radius: 16px;
  }

  >div>div>div {
    border-radius: 0px;
  }

  .MuiOutlinedInput-input {
    color: var(--secondary-color);
  }

  .MuiSwitch-root+span {
    color: var(--secondary-color);
  }

  .MuiFormControlLabel-root {
    .MuiCheckbox-root {
      .MuiIconButton-label {
        svg {
          color: var(--secondary-color);
        }
      }
    }

    .MuiFormControlLabel-label {
      color: var(--secondary-color);
    }
  }

  .dropzone-text {
    color: var(--secondary-color);
  }

  .custom-dropzone-image-wrapper {
    .image-custom-dropzone-div {
      &+div {
        color: var(--secondary-color);
      }

      svg {
        color: var(--secondary-color);
      }
    }
  }

  .dialog-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px 0px 20px;

    &.customer-csv {
      display: list-item;
      margin-bottom: 0;
    }

    button {
      min-height: 42px;
      padding: 8px 10px !important;
      min-width: 140px;
      font-weight: 600;

      &.button-btn {
        &.btn-custom-black {
          background-color: var(--secondary);
          color: var(--white);

          &:hover {
            background-color: var(--primary-color) !important;
            color: var(--white);
          }
        }

        &.btn-custom-primary {
          &:hover {
            background-color: var(--secondary) !important;
            color: var(--white);
          }
        }
      }
    }
  }

  &.postal-modal {
    .button-btn {
      &.btn-custom-border {
        min-width: 120px;
      }
    }

    div>div>div {
      justify-content: center;
    }

    >div>div {
      padding: 16px;
    }
  }
}

.cus-dialog-login {
  h2 {
    text-transform: uppercase;
    font-size: 20px;
    text-align: center;
    margin-top: 10px !important;
  }

  >div>div {
    border-radius: 16px;
  }

  >div>div>div {
    border-radius: 0px;
  }

  .dialog-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px 0px 20px;

    button {
      min-height: 42px;
      padding: 8px 10px !important;
      min-width: 140px;
      font-weight: 600;
      background-color: #00a88e !important;
      color: white;
      transition: all 0.5s ease;

      &:focus,
      &:hover {
        background-color: #00a88e !important;
        opacity: 0.8;
        color: #fff;
        transition: all 0.5s ease;
      }
    }
  }
}

.sidebar-right-side {
  .MuiDrawer-paperAnchorRight {
    left: auto;
    right: 0;
    width: 400px;
    background-color: #2a3347;
  }

  .header-sidebar-right {
    background: #94a3b8 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    align-items: center;
    padding: 20px 24px;
    justify-content: space-between;
    color: #0f172a;

    h2 {
      color: #0f172a;
    }

    svg {
      cursor: pointer;
    }

    +ul {
      padding: 0;
      margin: 0;

      li {
        list-style: none;

        label {
          font-weight: 600;
          color: var(--white);
        }

        .MuiPaper-root {
          background-color: transparent;
          color: var(--white);

          svg path {
            fill: var(--grey-white);
          }

          .Mui-checked {
            svg path {
              color: var(--primary-color);
            }
          }
        }

        .custom-search-field {
          display: flex;
          padding: 10px;
          width: 100%;
          align-items: center;
          height: 40px;
          border: 1px solid #94a3b8;
          color: var(--white);
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-border-radius: 4px;
          box-shadow: none !important;

          .search-icon {
            margin-left: 4px;
            margin-right: 8px;

            svg {
              color: var(--secondary-color);
            }
          }

          input {
            width: 100%;
            font-size: 16px;
            background: transparent;
            border: none;
            color: var(--black-color);
            outline: none;
            color: var(--white);
          }

          svg {
            color: var(--secondary-color);
          }
        }

        input {
          color: var(--white);

          &::placeholder {
            color: #94a3b8;
          }
        }

        .Mui-expanded {
          margin: 0 !important;
          min-height: 48px !important;
        }

        .style-list {
          margin-top: -10px;
          padding: 0;

          li {
            margin: 0 0 5px 0px;
            padding: 5px 0px;
            cursor: pointer;
            display: inline-block;
            font-size: 14px;
          }
        }

        .unselected {
          background: #808592;
          padding: 2px 8px;
          border-radius: 4px;
          font-size: 14px;
          margin-right: 10px;
          text-align: center;
          cursor: pointer;
        }

        .selected {
          background: var(--primary-color);
          padding: 2px 8px;
          font-size: 14px;
          border-radius: 4px;
          margin-right: 10px;
          text-align: center;
          cursor: pointer;
        }

        .select-custom {
          position: relative;
          width: 100%;

          >div {
            width: 100%;
            color: var(--white);
          }
        }
      }
    }
  }
}

.list-style-text {
  color: var(--black-color);
}

.text-white {
  color: var(--white);
  font-size: 16px;
}


div#menu- {
  .MuiMenu-paper {
    max-height: calc(100% - 96px);
    -webkit-overflow-scrolling: touch;
    z-index: 9999 !important;
  }
}

.goto-pagination {
  max-width: 120px;
  text-align: center;
  color: var(--secondary);
  border-radius: 30px;
  border: 0;
  margin-left: 20px;
  padding: 0 14px;
  background-color: var(--light-grey-color);
}

.rs-picker-default {
  .rs-btn {
    background-color: transparent !important;
  }

  .rs-picker-toggle {
    background-color: transparent !important;
  }

  &:not(.rs-picker-disabled) {
    .rs-btn-active {
      border-color: var(--white);
    }

    .rs-btn {
      &:focus {
        border-color: var(--white);
      }

      &:hover {
        border-color: var(--white);
      }
    }

    .rs-picker-toggle-active {
      border-color: var(--white);
    }

    .rs-picker-toggle {
      &:focus {
        border-color: var(--white);
      }

      &:hover {
        border-color: var(--white);
      }
    }
  }
}

.rs-picker-input {
  .rs-btn {
    background-color: transparent !important;
  }

  .rs-picker-toggle {
    background-color: transparent !important;
  }
}

.rs-picker-has-value {
  .rs-btn {
    .rs-picker-toggle-value {
      color: var(--secondary);
    }
  }

  .rs-picker-toggle {
    .rs-picker-toggle-value {
      color: var(--white);
    }
  }
}

.rs-picker-toggle-textbox {
  background-color: #2a3347;
}

.rs-picker-toggle-active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.rs-picker.rs-picker-focused {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.rs-picker-toggle-wrapper {
  width: 100%;
}

.cus-radio-design {
  svg path {
    fill: var(--border-color);

    svg {
      path {
        fill: var(--border-color);
      }
    }
  }
}

.search-add {
  display: flex;
  justify-content: flex-end;
}

.list-style-list {
  position: relative;

  .MuiBackdrop-root {
    background: #0f141da3;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100vh;
    max-width: 400px;
    right: 0px;
    left: unset;
  }
}

img {
  &.analytic-img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
}

.resloved_text {
  display: flex;
  width: 100%;
  height: 24px;
  padding: 2px 12px 2px 12px;
  justify-content: flex-end;
  align-items: center;
  max-width: fit-content;
  border-radius: 30px;
  color: #fff !important;

  &.green {
    background: #12B76A;
  }

  &.grey {
    background: #94A3B8;
  }
}